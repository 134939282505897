/* ================= CSS Default ================= */
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato-Regular.ttf);
}
@font-face {
  font-family: Lato-Light;
  src: url(../fonts/Lato-Light.ttf);
}
@font-face {
  font-family: Lato-Bold;
  src: url(../fonts/Lato-Bold.ttf);
}
body {
  font-family: 'Lato-Regular', Roboto, Arial, sans-serif;
  font-size: 14px;
  background: #141414;
  color: #868c9f;
}
li {
  list-style-type: none;
}
a,
img {
  transition: all 0.3s ease;
}
a {
  color: #fff;
}
a:focus {
  outline: none;
}
a:hover,
a:active,
a:link,
a:visited {
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
  font-family: Lato-Regular;
  line-height: 1.25;
  color: #fff;
  letter-spacing: 0.3px;
}
.padding0 {
  padding: 0;
}
b,
strong {
  font-weight: normal;
  font-family: Lato-Bold;
}

/* ================= CSS FULL (content you write code here) ================= */
/* ================= HEADER ================= */
body.offcanvas-active {
  overflow: hidden;
}

.space_section {
  padding: 60px 0;
}

.offcanvas-header {
  display: none;
}
.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s, width;
  opacity: 1;
  width: 100%;
  visibility: visible;
}

header {
  background: #060606;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 7;
}

.navbar,
.navbar-brand {
  padding: 0;
}

.auto-center {
  margin: 0 auto;
}

.sign .badge {
  border-radius: 50%;
  font-weight: normal;
  width: 25px;
  height: 25px;
  padding: 6px;
  top: 0;
  left: 10px;
  position: absolute;
}

.sign {
  margin-top: 11px;
}

.navbar-brand {
  padding: 7px 0;
}

.sign .nav-link {
  padding-top: 11px !important;
  padding-bottom: 0 !important;
}

#navbar_main .navbar-nav .nav-item .nav-link {
  font-size: 15px;
  padding: 18px 0;
  color: #fff;
}

#navbar_main .navbar-nav .nav-item {
  margin: 0 17px;
}

#navbar_main .navbar-nav .active .nav-link {
  color: #384bf2;
  border-bottom: 3px solid #384bf2;
}

#navbar_main .navbar-nav .nav-item .nav-link img {
  margin-right: 5px;
  margin-top: -3px;
}

.sign .dropdown-toggle {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sign .nav-item {
  margin-left: 25px;
  border-left: 0;
}

.sign .nav-item:first-child .nav-link {
  border-radius: 30px;
  padding: 7px 17px !important;
}

.sign .nav-item:last-child .nav-link {
  background: #384bf2;
  border: 1px solid #384bf2;
  color: #fff !important;
  border-radius: 5px;
  transition: all 0.3s ease;
  padding: 7px 17px !important;
}

.sign .nav-item:last-child .nav-link:hover {
  box-shadow: 0 4px 30px #384bf2;
}

.sign .nav-item {
  margin: 0 0 0 15px !important;
}

.dropdown-menu {
  background: #292c38;
  min-width: 200px;
}

.dropdown-menu .dropdown-item {
  padding: 15px 25px;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  opacity: 0.7;
}

.dropdown-menu .dropdown-item:hover {
  opacity: 1;
  background: #383b48;
}

.dropdown-menu .dropdown-item img {
  margin-right: 12px;
  margin-top: -4px;
}

#modalConnect .modal-content {
  width: 400px;
  margin: 0 auto;
  background-color: #1c1c20;
}

.btn-close:focus {
  box-shadow: none;
}

.modal-header,
.modal-footer {
  padding: 20px;
  border: 0;
}

.modal-title {
  width: 100%;
  text-align: center;
  font-size: 21px;
  color: #fff;
}

.modal-body {
  padding: 0 20px;
}

.modal-footer {
  margin: 0 auto;
}

.btn-cancel,
.btn-submit {
  border-radius: 30px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  width: 150px;
  text-align: center;
}

.btn-submit {
  color: #fff;
  background: #384bf2;
  border: 1px solid #384bf2;
}

.btn-submit:hover {
  box-shadow: 0 4px 30px #384bf2;
}

.btn-cancel {
  color: #fff;
  background: none;
  border: 1px solid #fff;
}

.btn-cancel:hover {
  color: #fff;
}

.group_connect a {
  display: block;
  background: #384bf2;
  color: #fff;
  text-align: center;
  font-family: Lato-Bold;
  border-radius: 5px;
  margin-bottom: 17px;
  padding: 15px 0;
  position: relative;
  font-size: 17px;
}

.group_connect a:hover {
  box-shadow: 0 4px 30px #384bf2;
}

.group_connect a img {
  max-width: 32px;
  position: absolute;
  top: 10px;
  left: 25px;
}

/* ================= MAIN ================= */

main {
  padding: 100px 0 0;
}

.title_main h2 {
  position: relative;
  color: #fff;
  font-size: 34px;
  margin-top: -8px;
}

.title_main h2:after {
  position: absolute;
  width: 50px;
  height: 2px;
  background: #7c7e94;
  content: '';
  bottom: -13px;
  left: 0;
}

.title_main {
  margin-bottom: 20px;
}

.search {
  position: relative;
}

.search input[type='text'] {
  background: #1d1e22;
  padding: 13px 105px 13px 42px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid transparent;
  width: 100%;
  outline: none;
}

.search input[type='text']:focus {
  box-shadow: none;
  border: 1px solid #384bf2;
}

.search img {
  position: absolute;
  top: 15px;
  left: 15px;
}

.avatar {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: 0.3s ease;
  opacity: 0.4;
  padding: 20px;
  margin-bottom: 0;
  text-align: center;
}

.overlay-02 {
  text-align: left !important;
}

.overlay-02 img {
  max-width: 40px;
  float: left;
  margin-right: 10px;
}

.overlay span small {
  display: block;
  color: #fff;
  font-size: 14px;
}

.overlay a {
  margin: 0 4px;
}

.avatar:hover .overlay {
  opacity: 1;
}

.item-box {
  background: #1c1c20;
  border-radius: 25px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
}

.item-box time {
  display: block;
  margin: 7px 0;
}

.item-box .exceprt {
  font-size: 13px;
  padding: 15px;
  border-radius: 10px;
  background: rgba(41, 44, 56, 0.75);
  overflow: auto;
  line-height: 1.6;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  color: #868c9f;
  border: 0;
  font-family: Lato-Bold;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  border: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #384bf2;
  border: 0;
  border-bottom: 2px solid #384bf2;
  background: none;
}

.nav-tabs {
  border-bottom: 1px solid #3c3d4d;
}

.tab-content {
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 235px;
}

.item-box h4 {
  font-size: 22px;
}

.item-box .tab-content ul {
  padding-left: 0;
  margin: 15px 0;
}

.item-box .tab-content ul li {
  margin-bottom: 15px;
}

.item-box .tab-content ul li span {
  font-family: Lato-Bold;
  display: block;
  color: #fff;
  font-size: 21px;
}

.item-box p {
  line-height: 1.6;
  margin-bottom: 0;
}

.text-ocean {
  color: #a8b1ff !important;
}
.text-ocean-2 {
  font-weight: normal;
  color: #a8b1ff !important;
}
.text-ocean-3 {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #8894ff !important;
  padding: 10px 0;
}
.border-bottom-color {
  border-bottom: 1px solid #444970;
}
.text-red {
  color: #fa4759 !important;
}

.text-blue {
  color: #384bf2 !important;
}

.text-green {
  color: #56a543 !important;
}

.bg-pink {
  background: #e250e5 !important;
  color: #fff !important;
}

.bg-pink:hover {
  box-shadow: 0 4px 30px #e250e5 !important;
}

.bg-gold {
  background: #e0a713 !important;
  color: #fff !important;
}

.bg-gold:hover {
  box-shadow: 0 4px 30px #e0a713 !important;
}

.bg-red {
  background: #fa4759 !important;
  color: #fff !important;
}

.bg-red:hover {
  box-shadow: 0 4px 30px #fa4759 !important;
}

.bg-gray {
  background: #2e3147 !important;
  color: #fff !important;
}

.bg-gray:hover {
  box-shadow: 0 4px 30px #2e3147 !important;
}

.bg-green {
  background: #56a543 !important;
  color: #fff !important;
}

.bg-green:hover {
  box-shadow: 0 4px 30px #56a543 !important;
}

.bg-blue {
  background: #384bf2 !important;
  color: #fff !important;
}

.bg-blue:hover {
  box-shadow: 0 4px 30px #384bf2 !important;
}

.link-a a {
  display: block;
  border: 1px solid #fff;
  color: #fff;
  font-family: Lato-Bold;
  border-radius: 30px;
  padding: 10px 0;
  text-align: center;
}

.link-a a:hover {
  background: #384bf2;
  border-color: #384bf2;
}

.link-a img {
  margin-right: 5px;
  margin-top: -4px;
}

.button-link-info a {
  display: block;
  /* border: 1px solid #fff; */
  color: #fff;
  font-family: Lato-Bold;
  border-radius: 30px;
  padding: 10px 0;
  text-align: center;
  background: #384BF2;
}

.button-link-info a:hover {
  background: #384bf2;
  border-color: #384bf2;
}

.button-link-info img {
  margin-right: 5px;
  margin-top: -4px;
}

.group_tags span {
  background: #181a2a;
  border-radius: 6px;
  padding: 3px 8px;
  border: 1px solid #3c3d4d;
  margin-right: 4px;
  font-size: 12px;
  color: #a8b1ff;
}

.item-card {
  background: #1c1c20;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}

.item-card img {
  border-radius: 15px;
  margin: 0 auto;
  display: block;
}

.item-card img:hover,
.avatar img:hover {
  filter: brightness(120%);
}

.item-card h4 {
  margin: 15px 0;
}

.item-card h4 img {
  border-radius: 0;
  float: right;
}

.item-card ul {
  padding-left: 0;
}

.item-card ul li {
  margin-bottom: 10px;
}

.item-card ul li span {
  float: right;
  font-size: 15px;
  padding-right:5px;
}

.item-card .link-a {
  opacity: 0;
  transition: all 0.3s ease;
}

.item-card:hover .link-a {
  opacity: 1;
}

.item-card .current {
  color: #a8b1ff !important;
  text-align: center;
  margin: 10px 0;
}

#manager {
  width: 60%;
  border-radius: 30px;
  padding: 8px 10px;
  display: block;
  background: #384bf2;
  color: #fff;
  margin: 0 auto;
}

#manager img {
  display: inline;
}

#manager:focus {
  box-shadow: none;
}

.dropdown-toggle::after {
  display: none;
}

.status-text {
  margin: 15px 0;
}

.progress {
  border-radius: 30px;
  height: 18px;
  background: #bcc3cf;
}

.progress-bar {
  background: #384bf2;
}

#nav-tab {
  width: 72%;
}

#nav-tab .nav-link {
  font-size: 15px;
  padding: 11px 20px;
}

.status-item {
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 120px;
  text-align: center;
  padding: 8px 0;
  font-family: Lato-Bold;
}

.box-detail-main {
  background: rgba(56, 75, 242, 0.11);
  border-radius: 10px;
  padding: 20px;
}

.box-bg {
  background: #384BF21C 11%;

  padding: 20px;
  border-radius: 15px;
  position: relative;
}

.title-box img {
  float: left;
  margin-right: 20px;
  display: block;
  max-width: 80px;
  border-radius: 6px;
}

.title-box .name-box h3 {
  font-size: 28px;
}

.back-link a {
  display: block;
  padding: 0 0 10px;
  margin: 0 0 10px;
  font-size: 15px;
}

.back-link a:hover {
  color: #fff;
}

.back-link img {
  max-width: 9px;
  margin-right: 7px;
}

.box-title-key {
  padding: 20px 0;
}

.box-title-key h3 {
  position: relative;
}

.box-title-key h3:after {
  background: #384bf2;
  content: '';
  width: 50px;
  height: 3px;
  position: absolute;
  bottom: -15px;
  left: 0;
}

.metric {
  padding-left: 0;
}

.metric li {
  width: 31%;
  background: linear-gradient(to right, rgba(50, 64, 189, 1) 0%, #1c1e2e 100%);
  border: 1px solid #384bf2;
  border-radius: 15px;
  text-align: center;
  margin-right: 15px;
  padding: 15px 10px;
  margin-bottom: 20px;
  float: left;
}

.metric li span {
  display: block;
  color: #fff;
  font-family: Lato-Bold;
  font-size: 18px;
}

.pool li:last-child {
  width: 96%;
}

.pool li:last-child span {
  display: inline;
  margin-left: 5px;
}

.list-network {
  position: absolute;
  top: 20px;
  right: 20px;
}

.list-network img {
  max-width: 45px;
  max-height: 45px;
  border-radius: 50%;
  padding: 7px;
  margin-left: 5px;
}

.tab-detail {
  width: 100% !important;
}

.list-group-item {
  background: none;
  color: #fff;
  padding: 13px 20px;
  margin: 7px 0;
  border-radius: 5px !important;
}

.list-group-item__active {
  background: #384bf2;
}

#list-spy {
  border-radius: 0;
}

.scrollspy-main h4 {
  font-family: Lato-Bold;
  font-size: 32px;
  margin-bottom: 20px;
}

.scrollspy-main p {
  font-size: 15px;
  line-height: 1.85;
  margin-bottom: 30px;
}

#spy_main {
  padding-top: 40px;
}

.title-step {
  padding-left: 0;
}

.title-step li {
  float: left;
  opacity: 0.3;
  text-align: center;
  color: #fff;
  position: relative;
}

.title-step li span {
  background: url('../images/step.png') no-repeat top center;
  display: block;
  padding: 10px;
  font-family: Lato-Bold;
  font-size: 22px;
  margin-bottom: 10px;
}

.title-step li.active {
  opacity: 1;
  color: #384bf2;
}
.title-step li.checked span {
  background: url('../images/checked.png') no-repeat top center;
}

.title-step li.checked {
  opacity: 1;
}
.title-step li.active span {
  color: #fff;
}

.title-step li:after {
  position: absolute;
  top: 20px;
  background: url('../images/arrow-1.png') no-repeat;
  width: 85px;
  height: 12px;
  content: '';
  right: -45px;
}

.title-step li.active:after {
  background: url('../images/arrow-2.png') no-repeat;
}

.title-step li:last-child:after {
  display: none;
}

.bg-step {
  background: #24252d;
  padding: 40px 25px;
  border-radius: 10px;
}

.create-new {
  background: #384bf2;
  padding: 13px 20px;
  border-radius: 4px;
}

.create-new:hover {
  color: #fff;
}

.create-new img {
  margin-right: 8px;
  margin-top: -3px;
}

.group_tool {
  float: right;
}

.group_tool .create-new {
  margin-right: 10px;
}

.list-menu-aside {
  padding-left: 0;
}

.list-menu-aside li {
  margin: 7px 0;
}

.list-menu-aside li a {
  display: block;
  padding: 15px 20px;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
}

.list-menu-aside li img {
  margin-right: 10px;
}

.list-menu-aside .active a {
  background: #384bf2;
}

.status-box {
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 8px 20px;
}

.status-box img {
  border-radius: 50%;
  background: #fff;
  padding: 5px;
  margin-right: 5px;
  margin-top: -3px;
}

.box-style .link-a {
  margin-top: 12px;
  float: right;
}

.box-style .link-a a {
  display: inline-block !important;
  width: 115px;
  background: #384bf2;
  border: 1px solid #384bf2;
  font-size: 13px;
  margin-right: 4px;
  opacity: 0.5;
}

.box-style .link-a a:hover {
  opacity: 1;
}

.bg-verify {
  background: #1b1b1f url('../images/bg-wave.png') no-repeat top left;
  background-size: 65%;
  position: relative;
  padding: 230px 0;
}

.email-p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 470px;
  margin: 0 auto;
}

.form-control {
  background: #262832;
  border: 1px solid #262832;
  height: auto;
  padding: 15px 20px;
  color: #fff;
  font-size: 15px;
  margin-bottom: 20px;
}

.form-control::placeholder {
  color: #d0d0d0;
}

.form-control:focus {
  box-shadow: none;
  background: none;
  border: 1px solid #384bf2;
  color: #fff;
}

.email-p .form-control {
  margin-bottom: 30px;
}

.email-p .btn-submit {
  margin: 0 auto;
  display: block;
}

.email-p {
  text-align: center;
  color: #fff;
}

.email-p p {
  font-size: 20px;
  margin-bottom: 30px;
}

.verify-email label {
  text-align: left;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.group_btn {
  margin-top: 30px;
}

.group_btn button {
  display: inline-block !important;
  margin: 0 5px !important;
}

.group_btn button img {
  margin-right: 6px;
  margin-top: -2px;
}

.status-img {
  padding: 14px;
  background: #fff;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  margin-bottom: 12px;
}

.box-radio {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  position: relative;
}

.box-radio:hover,
.verify-email label:hover {
  cursor: pointer;
}

.box-radio img {
  display: block;
  margin: 0 auto 10px;
  max-width: 100%;
  opacity: 0.6;
}

.box-radio:hover img {
  opacity: 1;
  filter: brightness(130%);
}

.box-radio .form-check-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box-radio .form-check-label small {
  position: absolute;
  bottom: 18px;
  left: 45px;
  font-size: 100%;
}

.form-check-input {
  width: 17px;
  height: 17px;
}

.bg-show {
  background-color: #202126;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0 0;
  min-height: 325px;
}

.bg-blue-gradient {
  background: linear-gradient(to right, #232f99 1%, #1a1c2b 100%);
}

.title-show h5 {
  position: relative;
  font-size: 27px;
}

.title-show h5:after {
  position: absolute;
  content: '';
  background-color: #384bf2;
  width: 60px;
  height: 4px;
  bottom: -22px;
  left: 0;
  z-index: 1;
}

.title-show hr {
  margin: 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.bg-blue-gradient ul {
  padding-left: 0;
  margin-bottom: 0;
}

.bg-blue-gradient ul li {
  margin-bottom: 24px;
  font-size: 15px;
}

.bg-blue-gradient ul li span {
  color: #fff;
  font-family: Lato-Bold;
  float: right;
}

.bg-show .btn-submit:hover {
  color: #fff;
}

.list-vc {
  padding-left: 0;
}

.list-vc li {
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 0;
}

.list-vc li span {
  float: right;
  color: #fff;
  font-family: Lato-Bold;
}

.bg-show .btn-join .btn-submit {
  width: 160px;
  display: block;
  float: right;
}

.text-a a {
  color: #a8b1ff;
  text-decoration: underline;
}

.milestone {
  padding: 60px 0;
}

.bg-frame {
  background: #1f1f26;
  padding: 30px;
  border-radius: 10px;
}

.bg-frame .title-show hr {
  display: none;
}

.bg-frame .title-show h5 {
  margin-bottom: 40px;
}

.group_btn .bg-red {
  border: 1px solid #fa4759 !important;
}

.group_btn .bg-gray {
  border: 1px solid #2e3147 !important;
}

.box-vote {
  margin-top: 40px;
}

.box-vote h2 {
  color: #a8b1ff;
  font-size: 21px;
  margin-bottom: 15px;
}

.box-vote h2 span {
  float: right;
  font-size: 17px;
}

.bg-dark {
  background-color: #17171c !important;
  padding: 30px;
  border-radius: 10px;
  position: relative;;
}
.bg-dark .chain-logo{
  position: absolute;
  right: 10px;
  top: 10px;
}
.border-gradient {
  border: 1px solid;
  border-image-slice: 1;
  border-radius: 10px;
  padding: 20px 0 20px 20px;
  border-image-source: linear-gradient(
    to right,
    #4a4a4e 0%,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 0) 100%
  );
}

.bg-dark h3 {
  margin-bottom: 20px;
  font-size: 17px;
  text-transform: uppercase;
  color: #dedede;
}

.bg-dark h3 span {
  float: right;
  color: #a8b1ff;
  position: relative;
  text-transform: none;
  margin-right: 25px;
}

.bg-dark h3 span b {
  color: #384bf2;
}

.bg-dark h3 span:after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #384bf2;
  top: 6px;
  left: -20px;
}

.border-gradient ul {
  padding-left: 0;
}

.border-gradient ul li {
  font-size: 16px;
  margin-bottom: 15px;
}

.border-gradient ul li img {
  margin-right: 7px;
  margin-top: -2px;
}

.border-gradient ul li span,
.border-gradient ul li b {
  color: #fff;
  font-family: Lato-Bold;
}

.border-gradient h5 {
  font-size: 17px;
  margin-bottom: 10px;
  height: 21px;
}

.border-gradient h5 span {
  text-transform: uppercase;
  color: #384bf2;
}

.modal .modal-content {
  background: #1a1e54 url('../images/bg-wave.png') no-repeat top left;
}

.warning-vote {
  color: #a8b1ff;
  font-size: 21px;
}

.warning-vote b {
  color: #fff;
}

.alert-sm {
  color: #fa4759;
  font-style: italic;
  font-size: 15px;
}

.after-done img {
  max-width: 18px;
}

.group_line {
  height: 12px;
  border-radius: 30px;
  width: 100%;
  overflow: hidden;
}

.group_line span {
  display: block;
  height: 12px;
  float: left;
}

.group_line .vote-y {
  background: #384bf2;
}

.group_line .vote-n {
  background: #fa4759;
}

.group_line .vote-e {
  background: #2e3147;
}

.custom-e .tooltip-inner {
  background: #2e3147;
  font-size: 13px;
  padding: 7px 15px;
}

.tooltip-arrow:before,
.tooltip-arrow:after {
  border-top-color: #2e3147 !important;
}

.group_line {
  margin-top: 50px;
}

.form-select {
  background: #292c38;
  color: #fff;
  border: 1px solid #292c38;
  padding: 13px 20px;
}

.form-select:focus {
  border: 1px solid #384bf2;
  box-shadow: none;
}

.bg-frame .search input[type='text'] {
  background: #292c38;
}

.table-responsive .table {
  color: #fff;
}

.table-responsive {
  background: #1a1b1f;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0;
}

.table-responsive .table th {
  color: #7c7e94;
}

.table-responsive .table td {
  padding: 15px 10px;
}

.table > :not(:first-child) {
  border-top: 0;
}

.bg-step .form-group label span {
  color: #fa4759;
}

.bg-step .form-group label {
  margin-bottom: 10px;
  color: #fff;
  display: block;
}

.bg-step .form-group label small {
  color: #7c7e94;
  font-size: 12px;
  float: right;
  margin-top: 3px;
}

.bg-step .form-group {
  margin-bottom: 35px;
}

.bg-step .form-group .form-control {
  background-color: #292c38;
}

.bg-step .form-group .form-control::placeholder {
  color: #616379;
}

.btn-group {
  float: right;
  margin-top: 30px;
  margin-bottom: 40px;
}

.btn-group button {
  margin-left: 12px;
}

.bg-step .btn-cancel,
.bg-step .btn-submit {
  width: 200px;
}

.mt-6 {
  margin-top: 60px;
}

.file-upload {
  background-color: #bcc3cf;
  border-radius: 10px;
}

.file-upload img {
  margin: 40px auto;
  display: block;
  max-width: 100px;
}

.note-p {
  line-height: 2.2;
}

.box-layout {
  background: #202027;
  padding: 20px;
  border-radius: 10px;
}

.box-layout h4 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 30px;
  padding-bottom: 10px;
  color: #a8b1ff;
}

.box-layout {
  position: relative;
  margin-bottom: 25px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.form-main {
  margin-bottom: 70px;
}

.tox .tox-menubar {
  background: #141414 !important;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary,
.tox .tox-statusbar {
  background: #272934 !important;
}

.bg-d {
  background: #24252d url('../images/d-blur.png') no-repeat top right;
}

.bg-d .title-show hr {
  width: 40%;
}

.step05 {
  font-size: 18px;
  margin-bottom: 10px;
}

.close-x {
  position: absolute;
  top: -8px;
  right: -8px;
  color: #141414;
  background: #c9c9c9;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 0;
  transition: all 0.3s ease;
}

.close-x:hover {
  background: #fa4759;
  color: #fff;
}

.bg-file {
  background: #2f323c;
  padding: 20px;
  border-radius: 10px;
  margin: 30px 0;
}

.table-responsive .table th {
  padding: 15px 10px;
  font-weight: normal;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.list-invest tr td small {
  display: block;
  font-size: 100%;
  color: #a8b1ff;
}

.list-invest td img {
  max-width: 32px;
  float: left;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 50%;
}

.table-responsive .table td {
  padding: 20px 10px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.only-p {
  margin-top: 15px;
  color: #fff;
  font-family: Lato-Bold;
  font-size: 18px;
}

.deposit_review {
  width: 450px;
  margin: 0 auto;
  text-align: center;
}

.deposit_review h5 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}

.box-main {
  background: #2c303c;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.box-main p {
  margin-bottom: 0;
  color: #fff;
  font-size: 18px;
  font-family: Lato-Bold;
}

.box-main p small {
  margin: 3px 7px 0;
}

.overview-pool ul {
  padding-left: 0;
}

.overview-pool ul li {
  margin-bottom: 10px;
}

.overview-pool ul li b {
  color: #a8b1ff;
  margin-left: 5px;
}

.overview-pool h4 {
  margin-bottom: 20px;
}

.figure-avatar p {
  text-align: center;
  margin-top: 8px;
  border-radius: 8px;
}

.figure-avatar img {
  border-radius: 5px;
}

/* ============ BEGIN: HOME PAGE ============ */
#introduction {
  background: url('../images/bg-wave.png') no-repeat bottom left;
  padding: 0 0 70px;
}

#introduction article h5 {
  font-size: 32px;
}

#introduction article h1 {
  font-size: 54px;
  color: #35fff3;
  line-height: 1.15;
  background: linear-gradient(to right, #8390ff, #384bf2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 25px;
}

#introduction p {
  margin-top: 20px;
  font-size: 18px;
  line-height: 1.9;
}

.img-intro {
  position: relative;
  z-index: 1;
}

.img-intro .abs {
  position: absolute;
  top: 35px;
  right: -12px;
  z-index: -1;
}

.abs-2 {
  top: 65px !important;
  right: -48px !important;
}

#introduction .text_a a {
  width: 170px;
  text-align: center;
  display: inline-block;
}

.text_a {
  margin-top: 60px;
}

.text_a a {
  color: #fff;
  margin-right: 15px;
  font-size: 17px;
  border-radius: 30px;
}

.btn-a {
  color: #fff !important;
  background: #384bf2;
  padding: 15px 30px;
  border-radius: 5px;
  border: 1px solid #384bf2;
}

.btn-a:hover {
  box-shadow: 0 4px 30px #384bf2;
}

.btn-a-o {
  background: none;
  border: 1px solid #848484;
}

.btn-a img {
  width: 24px;
  margin-left: 10px;
  margin-top: -3px;
}

.span_text {
  color: #8390ff;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid #d0d0d0;
  border-radius: 7px;
  padding: 8px 15px;
  z-index: 4 !important;
  position: absolute;
}

.abs-3 {
  right: auto !important;
  left: -20px;
}

.abs-4 {
  top: 95px !important;
  left: 20px;
  right: auto !important;
}

.abs-5 {
  top: auto !important;
  bottom: 80px;
  right: -35px;
}

.abs-6 {
  top: auto !important;
  left: -45px;
  right: auto !important;
  bottom: 30px;
}

#introduction .fanpages {
  margin-top: 40px;
}

.fanpages,
.vclist {
  padding-left: 0;
}

.fanpages li,
.vclist li {
  float: left;
}

.fanpages li a {
  display: block;
  margin: 0 5px;
  padding: 2px;
}
.vclist li a {
  display: block;
  margin-right: 8px;
  padding: 2px;
}
.fanpages li img {
  max-width: 28px;
}
.vclist li img {
  max-width: 20px;
}
.animate {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 25%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.one {
  animation-delay: 0.2s;
}
.two {
  animation-delay: 0.4s;
}
.three {
  animation-delay: 0.6s;
}
.four {
  animation-delay: 0.8s;
}
.five {
  animation-delay: 1s;
}
.six {
  animation-delay: 1.2s;
}
.seven {
  animation-delay: 1.4s;
}
.eight {
  animation-delay: 1.6s;
}

.opacity {
  animation: 3.2s ease 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.key_frame_3 {
  animation: moveleftdeep 1s infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
}

.key_frame_4 {
  animation: moveleft 1s infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
}

.key_frame_5 {
  animation: moveright 1s infinite;
  animation-direction: alternate;
  animation-timing-function: ease;
}

@keyframes moveleftdeep {
  from {
    left: 35px;
  }
  to {
    left: 45px;
  }
}

@keyframes moveleft {
  from {
    left: -15px;
  }
  to {
    left: 15px;
  }
}

@keyframes moveright {
  from {
    right: -25px;
  }
  to {
    right: 0px;
  }
}

.title-home {
  margin-bottom: 30px;
}

.title-home h2 {
  font-size: 42px;
  position: relative;
  font-weight:bold;
}

.title-home h2:before {
  position: absolute;
  width: 50px;
  height: 3px;
  content: '';
  bottom: -22px;
  left: 0;
  background: #384bf2;
}

.title-home h2 span {
  color: #384bf2;
}

.title-home hr {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 20px 0;
}

.title-home .text_a {
  float: right;
  margin-top: 40px;
}

.title-home .text_a a {
  padding: 13px 30px;
  border-radius: 30px;
  font-size: 14px;
  margin-left: 15px;
  margin-right: 0;
}

#pool-home .title-home hr {
  width: 200px;
  margin: 20px auto;
}

#pool-home .title-home h2:before {
  left: 48%;
}

#pool-home .text_a {
  text-align: center;
  float: none;
}

#about-us article {
  padding-left: 40px;
  position: relative;
  min-height: 200px;
}

#about-us article h2 {
  font-size: 48px;
  margin-bottom: 30px;
}

#about-us article h2 span,
#built h4 span {
  color: #35fff3;
}

#about-us article p {
  line-height: 1.7;
  font-size: 15px;
}

#about-us .title-home h2 {
  font-size: 24px;
}

#about-us .title-home h2:before {
  bottom: -32px;
}

#about-us h3 {
  background: linear-gradient(to right, #8390ff, #384bf2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  margin-bottom: 10px;
}

.box-invest {
  background: #000;
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.box-invest img {
  max-width: 55px;
}

.box-invest small {
  font-size: 16px;
  color: #a8b1ff;
  font-family: Lato-Bold;
  margin-left: 5px;
}

.box-invest p {
  font-size: 18px !important;
  font-family: Lato-Bold;
  color: #fff;
}

.focus {
  margin: 60px 0;
}

.focus article {
  text-align: center;
  padding: 30px 20px;
  border-radius: 10px;
  background: linear-gradient(to right, #23242b 0%, #19191c 100%);
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.focus article:hover {
  background: linear-gradient(to right, #232b71 0%, #19191c 100%);
  border: 1px solid #232b71;
  color: #fff;
}

.focus article img {
  max-width: 76px;
  display: block;
  margin: 0 auto 10px;
}

.focus article p {
  margin-bottom: 0;
  font-size: 16px;
}

.rel-img {
  position: relative;
}

.rel-img .img-fluid {
  max-width: 100%;
  margin-left:55px;
}

.upload-main {
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: relative;
  z-index: 1;
}

.abs-bg {
  position: absolute;
  top: 30px;
  right: 0px;
  z-index: 0;
  max-width: 100% !important;
  display: block;
}

#features article {
  padding-top: 20px;
}

#features article:first-child ul {
  padding-right: 35px;
}

#features article:last-child ul {
  padding-left: 35px;
}

#features article ul {
  padding-left: 0;
}

#features article ul li {
  margin-bottom: 40px;
}

#features article ul li span {
  display: block;
  margin-bottom: 15px;
  font-family: Lato-Bold;
  font-size: 38px;
  background: linear-gradient(to right, #8390ff, #384bf2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#features article ul li small {
  font-size: 100%;
  line-height: 1.8;
}

#features article ul li img {
  display: inline-block;
  margin-right: 10px;
}

.artic02 .pos-01 {
  margin-top: 20px !important;
}

.artic02 .pos-02 {
  margin-top: -50px !important;
}

.bg-grid {
  background: url('../images/grid.svg') no-repeat center top;
  background-size: 100%;
  padding-top: 60px;
}

.group_img .pos-01 {
  margin-top: 100px;
}

.group_img .pos-02 {
  margin-top: 20px;
}

.group_img img {
  border-radius: 25px;
}

#features .title-home hr {
  width: 200px;
}

#dao-funding {
  margin: 60px 0;
  background: #384bf2;
}

#interested {
  background: linear-gradient(to bottom, #374aec 0%, #7583fd 100%);
  color: #fff;
}

#interested .title-home hr {
  width: 170px;
}

#interested .title-home h2{
  font-size:68px;
  font-weight:Bold;
}

#interested .title-home h2:before {
  background: #fff;
}

#interested article p {
  font-size: 20px;
}

#interested .text-a {
  margin-top: 40px;
}

#interested .btn-a {
  border-radius: 30px;
  background-color: #fff;
  color: #384bf2 !important;
  text-decoration: none;
  border: 0;
  font-family: Lato-Bold;
}

.bg-img {
  background: url('../images/list.png') no-repeat center right;
  padding: 140px 0;
}

.bg-title {
  background: #1e1f26;
  border-radius: 10px;
  padding: 40px 30px;
}

#learn .text_a {
  margin-top: 20px;
}

#learn .accordion-button img {
  margin-right: 15px;
}

#learn .accordion-button {
  font-size: 17px;
  font-weight: normal;
  font-family: Lato-Bold;
  background: none;
  color: #fff;
}

#learn .accordion-item {
  background: none !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

#learn .accordion-body {
  line-height: 2.2;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

#partner img {
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0;
  border-radius: 5px;
  background: #1a1a22;
  width: 94%;
}

#partner img:hover {
  background: #25252f;
}

#partner .title-home hr {
  width: 200px;
  margin: 30px auto 40px;
}

#partner .title-home h2:before {
  bottom: -32px;
  left: 48%;
}

#dao-funding .carousel-item h6 {
  background: #fff;
  border-radius: 30px;
  color: #384bf2;
  font-family: Lato-Bold;
  padding: 10px 20px;
  font-size: 15px;
  width: 180px;
  text-align: center;
}

#dao-funding .carousel-item h6 img {
  max-width: 20px;
  margin-right: 7px;
  display: inline-block;
}

#dao-funding .carousel-item h3 {
  font-size: 43px;
  text-transform: uppercase;
  margin: 30px 0;
  position: relative;
}

#dao-funding .carousel-item h3:after {
  content: '';
  position: absolute;
  bottom: -32px;
  left: 0;
  background: #fff;
  width: 50px;
  height: 3px;
}

#dao-funding .carousel-item hr {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

#dao-funding .carousel-item img {
  border-radius: 35px;
  display: block;
  margin: 0 auto;
}

#dao-funding .carousel-item p {
  color: #b5bdff;
  margin-top: 30px;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}

.carousel-control-prev {
  left: -20px;
}

.carousel-control-next {
  right: 2px;
}

.box-img-abs {
  position: relative;
}

#dao-funding .carousel-inner {
  overflow: visible;
}

.box-img-abs span {
  position: absolute;
  bottom: -15px;
  left: -15px;
  background: #fff;
  padding: 8px 60px;
  border-radius: 10px;
  color: #384bf2;
  font-size: 21px;
  font-family: Lato-Bold;
}

.box-img-abs .abs-icon {
  position: absolute;
  top: 25%;
  right: 0;
  background: #fff;
  padding: 10px 20px;
  border-radius: 30px;
}

#dao-funding .carousel-indicators {
  right: auto;
}

.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.carousel-indicators .active {
  background-color: #35fff3;
}

/* ============ END: HOME PAGE ============ */
#nav-investment {
  width: 100%;
}

#nav-investment .nav-item.show .nav-link,
#nav-investment .nav-link {
  font-family: Lato-Regular;
  font-size: 13px;
  padding: 15px 20px;
}

#nav-investment-content thead tr th {
  background-color: #272934;
}

#nav-investment-content .table-responsive {
  padding: 0;
}

#nav-investment-content tr td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding: 20px 15px;
}

.applied tr td:first-child {
  width: 300px;
}

.bg-deposit {
  background: linear-gradient(to right, #13194e 0%, #17192a 100%);
}

.list-invest tr td:first-child {
  width: 200px;
}

.alert-deposit {
  color: #7c7e94;
}

.alert-deposit b {
  color: #fff;
}

.bg-deposit .btn-submit {
  padding: 8px 40px;
  width: auto;
}

.claim .btn-submit {
  padding: 5px 18px;
  width: auto;
  font-size: 12px;
  margin-left: 10px;
  position: relative;
  top: -2px;
}

/* ================= FOOTER ================= */
footer {
  color: #fff;
  background: #150030;
}

.logo-footer img {
  max-width: 180px;
}

.logo-footer p {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 30px;
  color: #35fff3;
  line-height: 1.2;
  font-family: Lato-Bold;
}

.logo-footer p strong {
  background: linear-gradient(to right, #8390ff, #384bf2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-footer p small {
  display: block;
  font-size: 15px;
  text-transform: none;
  margin-top: 10px;
  color: #fff;
  line-height: 1.5;
}

.copyright {
  margin-bottom: 0;
}

.copyright strong,
footer h5,
.menu-footer li a:hover {
  color: #384bf2;
}

footer .title-home h2 {
  font-size: 18px;
}

footer .title-home hr {
  width: 150px;
}

.menu-footer {
  padding-left: 0;
}

.menu-footer li a {
  display: block;
  color: #fff;
  font-size: 14px;
  padding: 0 0 20px;
}

.footer-bottom ul {
  padding-left: 0;
  float: right;
}

.footer-bottom ul li {
  float: left;
  margin-left: 10px;
  position: relative;
}

.footer-bottom ul li:after {
  position: absolute;
  top: 6px;
  left: 0;
  content: '';
  background: #384bf2;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.footer-bottom ul li a {
  display: block;
  padding-left: 20px;
}

/* ================= Few Option ================= */
.mg-auto {
  margin: 0 auto;
  display: block;
}

.bg-gradient-blue {
  background: linear-gradient(to bottom, rgba(50, 64, 189, 1) 0%, #24252d 18%, #24252d 100%);
  padding: 0;
}

.bg-gradient-blue .bg-d {
  padding: 40px 25px 0;
  background: url('../images/d-blur.png') no-repeat top right;
  color: #fff;
}

.text_underline a {
  text-decoration: underline;
  color: #a8b1ff;
}

.mg-80 {
  margin: 80px -10px;
}

.bg-gradient-blue .form-select {
  background: #292c38 url('../images/drop.svg') no-repeat 98% 20px;
}

.box-investing {
  background: linear-gradient(to right, #131951 0%, #181a2c 100%);
  padding: 30px 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
}

.box-investing h5 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 15px;
}

.box-investing p {
  margin-top: 10px;
  color: #a8b1ff;
}

.bg-manage {
  padding: 0;
}

.bg-box .btn-submit {
  padding: 6px 10px !important;
  width: 130px;
  margin: 0 5px;
}

.bg-box tr th {
  text-transform: uppercase;
}

.box-info-asey {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.box-info-asey p {
  color: #fff;
  font-size: 16px;
}

#modal-venture .modal-header h3 {
  text-align: center;
  width: 100%;
}

#listProject {
  /* height: calc(100vh - 130px - 89px) !important; */
  padding-right: 10px;
}
/* #poolListItem {
  height: calc(100vh - 130px - 89px) !important;
  padding-right: 10px;
} */
#listVcs {
  height: calc(100vh - 130px - 89px) !important;
  padding-right: 10px;
}
.bg-team-member {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 20px;
  padding: 80px;
  background-image: linear-gradient(to bottom, #202868, rgba(32, 40, 104, 0));
  margin-bottom: 50px;
}
.item-member {
  margin: 20px 0;
  padding: 40px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 30%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.19);
}
.item-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.linkedin-member {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 25px;
}
.content-metrics {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.title-metrics {
  color: #fff;
  font-size: 30px;
}
.content-metrics .tokenomics {
  /* padding: 24px; */
  border-radius: 20px;
  margin-top: 30px;
  background-image: linear-gradient(to top, #24252d, rgba(36, 37, 45, 0.25));
}
.table-team-member .table > :not(caption) > * > * {
  padding: 30px;
}

.vcs-list {
  border-radius: 5px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}
.vcs-detail {
  margin-top: 30px;
  border-radius: 10px;
  background-image: linear-gradient(#24252d 0%, rgba(36, 37, 45, 0.25) 100%);
  padding: 40px;
  display: flex;
  justify-content: space-between;
}
.vcs-list .filter,
.vcs-detail .profile {
  border-radius: 5px;
  background-image: linear-gradient(rgba(56, 75, 242, 0.76) 0%, rgba(33, 33, 39, 0.17) 100%);
  padding: 20px;
}
.clear-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
  border: 1px solid #494949;
  border-radius: 8px;
  cursor: pointer;
}
.vc-item-filter {
  margin: 0 auto;
  padding: 1em;
}
.vc-item-filter .checkboxField:before {
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 2px solid;
  border-radius: 0;
  background-color: #fafafa;
  border-color: transparent;
  box-sizing: border-box;
  color: #979797;
  content: close-quote;
  display: inline-block;
  height: 15px;
  outline: 1px solid #979797;
  transition-duration: 0.5s;
  transition-property: background-color, border-color;
  width: 15px;
}

.vc-item-filter .checkboxField:checked:before {
  background-color: #65be31;
  border-color: white;
}
.vc-item-filter label {
  padding-left: 10px;
  font-size: 13px;
  font-weight: normal;
}
.clear-filter .text {
  font-size: 15px;
  color: #e9e9e9;
  padding-left: 5px;
}
.title-select {
  font-size: 15px;
  color: #e9e9e9;
}
.vc-action {
  margin-top: 20px;
  min-width: 12vw;
  margin-left: 10px;
}
.vc-action .btn-detail,
.vc-action .btn-view {
  margin: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 6vw;
  padding: 6px 0;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  font-size: 13px;
}
.filter-select .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: rgba(41, 44, 56, 0.75);
  border: none;
  border-radius: 8px;
  padding-left: 10px;
}
.vcs-list .table-data {
  padding-left: 10px;
}

.vcs-list .table-data .ant-table-content .ant-table-thead > tr > th {
  background-color: #272934;
}
.vcs-list .table-data .ant-table-content .ant-table-cell {
  color: #676d7c;
  font-weight: 600;
}

.profile-info .info-item {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
}
.info-item .content-profile {
  font-size: 20px;
  font-weight: 600;
  width: 80%;
  color: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid #444970;
}
.content-title {
  display: flex;
  align-items: center;
}

.banner-vc {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 21vh;
  background-image: url('../../assets/images/banner-vcs.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}
.banner-vc .btn-join {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 220px; 
  color: #000;
  padding: 10px 0;
  font-weight: bold;
  border: 1px solid #000000;
  margin-right: 10%;
  margin-bottom: 5%;
}

.banner-buidls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 21vh;
  background-image: url('../../assets/images/buidls-banner.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

.banner-buidls div {
  font-size: 50px;
  color: #CBD0FF;
  margin-left: 56px;
  text-transform: uppercase;
  font-weight: 500;
}
/* ======== Update 2022/08/16 14:20 ======== */
.title-step li.checked span {
  background: url('../images/checked.png') no-repeat top center;
}

.title-step li.checked {
  opacity: 1;
}

#spy_main .scrollspy-main img {
  max-width: 60% !important;
  display: block;
  height: auto !important;
}

#recipeCarousel .card {
  background: none;
}

.carousel-item .row {
  width: 100%;
}

#dao-funding .carousel-control-next,
#dao-funding .carousel-control-prev {
  top: auto;
}

.vc-detail-overview-1 {
  width: 25%;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.vc-detail-overview-1 img {
  width: 50%;
  border-radius: 10px;
}
.vc-detail-overview-1 .name {
  font-size: 25px;
  color: #fff;
  font-weight: 600;
}

.vc-detail-overview-2 {
  width: 72%;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding: 0 30px;
}
.vc-detail-overview-2 .invest-item {
  text-align: center;
  border-radius: 10px;
  border: 1px solid #8b8d97;
  padding: 8px 10px;
  margin: 0 5px;
  font-size: 12px;
}
.vc-detail-overview-3 {
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.11);
  padding: 10px 30px;
}

.vc-investment-title {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.table-custom {
  padding: 10px;
}
.table-custom .ant-table-thead > tr > th {
  background-color: #384bf2;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
}
.table-custom
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background-color: #2a2a37;
}

.clear-filter {
  margin-bottom: 20px;
}

.item-card .img-vcs {
  background-color: rgba(0, 0, 0, 0.5);
}

.white-color {
  color: #fff;
}
.filter-show-more {
  cursor: pointer;
  font-size: 14px;
}
.vc-detail-overview-2 .title_main h2 {
  font-size: 28px;
}
.info-item .content-profile {
  font-size: 16px;
}
.box-img {
  margin-top: 15px;
  width: 90%;
  height: 268px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.05);
}

.box-img img {
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.ul-nopdf,
.ul-nopdf li {
  margin-bottom: 0 !important;
}

#list-build .avatar .overlay{
	padding:10px;
}

#list-build .item-box .nav-tabs .nav-link{
	padding:10px 20px !important;
}

#list-build .tab-content ul li{
	float:left;
	width:30%;
	margin-bottom:0;
}

#list-build .tab-content ul li span{
	color:#A8B1FF;
	font-size:20px;
}

#list-build .avatar{
	overflow:hidden;
	position: relative;
	height:185px;
	border-radius:15px;
}

#list-build .avatar img{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#list-build .overlay img{
	transform:none;
	position:static;
}

#list-build .item-box{
	padding:15px 15px 0 15px;
}

#list-build .item-box h4{
	font-size:22px;
}

#list-build .item-box .link-a{
	margin-top:140px;
}

#list-build .item-box .link-a a{
	background:#384BF2;
	border:0;
	font-size:13px;
}

#list-build .item-box .tab-content{
	min-height:160px;
}

#list-build .item-box .tab-content ul{
	margin-top:40px;
}

/* ======== Update 2022/09/29 17:00 ======== */
.box-detail-summary{
	background:linear-gradient(180deg,#23242B 1%,#1A1B1E);
	padding:25px 25px 25px 50px;
}

.box-detail-summary ul{
	padding-left:0
}

.social li{
	float:left;
	margin-right:100px;
}

.social li a{
	color:#868C9F;
	display:block;
}

.social li img{
	margin-top:-3px;
	margin-right:5px;
}

.box-img{
	overflow:hidden;
	border-radius:10px;
	position:relative;
	max-width:100%;
	height:206px;
	background:rgba(255, 255, 255, 0.05);
}

.box-img img{
	position: absolute;
	top: 50%;
	left: 50%;
	border-radius:10px;
	transform: translate(-50%, -50%);
}

.box-detail-summary{
	margin-top:130px;
	margin-bottom:60px;
	border-radius:15px;
}

.box-detail-summary aside{
	margin-top:-90px;
}

.box-detail-summary aside h5{
	margin:20px 0 30px;
	font-size:25px;
}

.box-detail-summary aside ul li{
	margin-bottom:30px;
}

.box-detail-summary aside ul li img{
	margin-right:15px;
	position:relative;
	top:-2px;
}

.box-detail-summary aside ul li span{
	border-bottom:1px solid #444970;
	padding-bottom:20px;
	color:#fff;
	font-weight:600;
	font-size:16px;
}

.box-detail-summary aside ul li:last-child span{
	border-bottom:0;
	padding-bottom:0;
}

.box-detail-summary aside ul li b{
	color:#8894FF;
}

.box-detail-summary .title_main{
	margin-top:40px;
}

.box-detail-summary .title_main h2{
	font-size:20px;
}

.box-trans{
	background:rgba(0,0,0,0.3);
	padding:20px;
	border-radius:10px;
	min-height:230px;
}

.box-trans h4{
	margin-bottom:10px;
}

.box-trans p{
	color:#fff;
	line-height:1.8;
	margin-bottom:0;
}

.box-trans ul li{
	float:left;
	width:47%;
	border:1px solid #5E77FF;
	border-radius:10px;
	background:#0e1344;
	padding:10px;
	text-align:center;
	font-size:13px;
	margin-bottom:10px;
	margin-right:10px;
}

.box-trans ul li b{
	display:block;
	color:#fff;
	font-weight:600;
	font-size:15px;
}

.content-detail-summary .nav-tabs{
	margin-top:-100px;
}

.content-detail-summary .nav-tabs .nav-item.show .nav-link, 
.content-detail-summary .nav-tabs .nav-link.active{
	color:#8894FF;
	border-bottom:2px solid #8894FF;
}

.content-detail-summary .tab-content{
	padding-top:52px;
}

.investment_de ul li b{
	border:1px solid rgba(255, 255, 255, 0.4);
	padding:4px 15px;
	border-radius:30px;
	font-size:12px;
	color:#fff;
	margin-right:7px;
}

.investment_de ul li{
	margin-bottom:20px;
}

.investment_de ul li small{
	margin-right:10px;
	font-size:14px;
	min-width: 50px;
	display: inline-block;
}

.dibi{
	padding-left:80px;
}

#list-build .avatar .overlay{
	padding:10px;
}

#list-build .item-box .nav-tabs .nav-link{
	padding:10px 20px !important;
}

#list-build .tab-content ul li{
	float:left;
	width:30%;
	margin-bottom:0;
}

#list-build .tab-content ul li span{
	color:#A8B1FF;
	font-size:20px;
}

#list-build .avatar{
	overflow:hidden;
	position: relative;
	height:185px;
	border-radius:15px;
}

#list-build .avatar img{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#list-build .overlay img{
	transform:none;
	position:static;
}

#list-build .item-box{
	padding:15px 15px 0 15px;
}

#list-build .item-box h4{
	font-size:22px;
}

#list-build .item-box .link-a{
	margin-top:140px;
}

#list-build .item-box .link-a a{
	background:#384BF2;
	border:0;
	font-size:13px;
}

#list-build .item-box .tab-content{
	min-height:160px;
}

#list-build .item-box .tab-content ul{
	margin-top:40px;
}

.dibi{
	margin-top:50px;
}

.investment_de{
	padding-left:80px;
}

.de-title li{
	margin-bottom:20px;
}

.de-title li small{
	font-size:100%;
	margin-right:7px;
}

.de-title li b{
	color:#8894FF;
}

.public-builds{
  background: linear-gradient(to right, #1a2259 0%, #121844 100%);
  border-radius:15px;
}

.public-builds h4{
  font-weight:600;
  font-size:34px;
  margin-bottom:30px;
  margin-top:30px;
}

.public-builds p{
  font-size:17px;
  line-height:1.8;
  color:#b0b0b0;
}

.public-builds ul{
  padding-left:0;
  margin-top:50px;
  margin-bottom:50px;
}

.public-builds ul li{
  float:left;
  margin-right:10px;
  background-color:#0c1031;
  border:1px solid rgba(255, 255, 255, 0.3);
  padding:10px 35px;
  border-radius:7px;
  color:#a5aefb;
  font-weight:600;
}

.rel-img{
  position: relative;
  overflow: hidden;
  border-radius:20px;
}

.box-abs-d{
  position: absolute;
  bottom:0;
  left:0;
  width:100%;
  padding:20px;
  background-color: rgba(255,255,255,0.12);
}

.box-abs-d img{
  max-width:65px;
  float:left;
  margin-right:15px !important;
  display: block !important;
}

.box-abs-d h3{
  text-transform: uppercase;
  font-weight:600;
  font-size:35px;
}

.box-abs-d h3 span{
  opacity: 0.6;
  font-size:18px;
  display: block;
  font-weight:normal;
}

.project-pub-item .item-box .avatar img{
  position: absolute;  top: 50%;  left: 50%;  transform: translate(-50%, -50%);
}

.project-pub-item .item-box .avatar .overlay img{
  position: static !important;
  transform: none !important;
}

.overlay img{
  transform: none !important;
  position: static !important;
  float: left;
}

.overlay a{
  display: inline-block;
  text-align:center;
}



/* ==================== Update CSS: 2022/11/02 ==================== */
.modal .modal-content{
	background:#1D1C23;
}

.modal-body{
	border-bottom:1px solid rgba(255, 255, 255, 0.1);
}

.group_connect a, .group_connect button, .group_connect .form-check {
	display:block;
	background:#384BF2;
	color:#fff;
	text-align:center;
	font-family:Lato-Bold;
	border-radius:5px;
	margin-bottom:17px;
	padding:15px 0;
	position:relative;
	font-size:17px;
}

.group_connect .form-check{
	text-align:left;
	padding:0;
}

.group_connect .form-check:hover,
.form-check-input:checked[type="radio"] + .group_connect .form-check{
	background:#1E2881;
}

.group_connect .form-check .form-check-label{
	margin-left:20px;
	display:block;
	padding:15px 0;
}

.group_connect .form-check .form-check-label img{
	margin-right:10px;
	margin-left:20px;
	max-width:28px;
}

.group_connect .form-check-input{
	width:22px;
	height:22px;
	border: 2px solid #fff;
	background:#384BF2;
}

.group_connect .form-check .form-check-input{
	margin-left:20px;
	margin-top:18px;
}

.group_connect button{
	border:0;
	width:100%;
}

.group_connect a:hover, .group_connect button:hover{
	box-shadow:0 4px 30px #384BF2;
}

.group_connect a img, .group_connect button img{
	max-width:32px;
	position:absolute;
	top:10px;
	left:25px;
}


.btn-cancel, .btn-submit{
	border-radius:7px;
	padding:8px 20px;
	transition:all 0.3s ease;
	width:150px;
	text-align:center;
}

.btn-submit{
	color:#fff;
	background:#384BF2;
	border:1px solid #384BF2;
}

.btn-submit:hover{
	box-shadow:0 4px 30px #384BF2;
}

.btn-cancel{
	color:#fff;
	background:none;
	border:1px solid rgba(255, 255, 255, 0.4);
}

.btn-cancel:hover{
	color:#fff;
}

.content-convert{
	margin:45px 0;
}

.item-convert{
	text-align:center;
	position:relative;
	z-index:1;
}

.pd0{
	padding:0;
}

.item-convert p{
	color:#fff;
	margin-top:8px;
	margin-bottom:0;
	font-family:Lato-Bold;
	font-size:18px;
}
.item-convert img{
	width: 60px;
}

.convert-to{
	position:relative;
}

.convert-to:before{
	position:absolute;
	content:'';
	border:1px dashed #d0d0d0;
	width: 180px;
	height: 1px;
	top: 30px;
	right: -100px;
	z-index:-1;
}

.convert-to:after{
	position:absolute;
	content:'';
	width:33px;
	height:33px;
	top: 15px;
	right: -17px;
	border-radius:50%;
	background:url('../images/convert.png') no-repeat;
}

#navbar_main .navbar-nav .nav-item{
	margin:0 12px;
}

.multichain img{
	max-width:24px;
}

.multichain .nav-link {
	background: #0E0C0C;
	padding: 8px 10px !important;
	border-radius: 6px;
}

#navbar_main .navbar-nav .nav-item .nav-link{
	padding:20px 0;
}

.dropdown-menu{
	background:#1C1D24;
	padding:0;
}

.dropdown-menu .dropdown-item:hover{
	background:#09090E;
}

header{
	background:#191919;
}

.sign .nav-item:first-child .nav-link{
	border-radius:6px;
}



.bg-step-2 {
  background-image: linear-gradient(151.88deg, rgba(36, 37, 45, 0.13) 0%, rgba(36, 37, 45, 0.25) 100.81%);
  padding: 40px 25px;
  border-radius: 10px;
}

.bg-step-item {
  margin-bottom: 100px;
  background: rgba(0, 0, 0, 0.11);
  border: 0.5px solid rgba(124, 126, 148, 0.6);
  border-radius: 7px;
  padding: 25px 40px;
}

.bg-step-item-2{
  padding: 25px 40px;
}